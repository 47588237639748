import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { radius } from 'theme'
import { Flex, Box } from 'components/Layout'

const Calendly = ({ link, height }) => {
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head.appendChild(script)
  }, [])

  return (
    <Flex alignItems="center" flexDirection="column" textAlign="center">
      <Box
        className="calendly-inline-widget"
        data-url={link}
        style={{ width: '100%', padding: '0px', overflow: 'hidden', borderRadius: radius.l }}
        height={height || { mobile: '1450px', mobileLarge: '1350px', desktopWide: '1200px' }}
      />
    </Flex>
  )
}

Calendly.defaultProps = {}

Calendly.propTypes = {
  link: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Calendly
