import React, { useEffect } from 'react'
import styled from 'styled-components'
import App from 'App'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { H1, H3, Text } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'
import Calendly from 'components/Calendly'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import bgImageSocialMedias from 'static/images/home/bg_social_medias.webp'

import logoBig from 'static/svg/logo_big.svg'

import imageFeatureConnect from 'static/images/smm-landing/connect.svg'
import imageFeatureCalendar from 'static/images/smm-landing/calendar.svg'
import imageFeatureTeam from 'static/images/smm-landing/team.svg'
import imageFeatureAccess from 'static/images/smm-landing/access.svg'

import imagePlans from 'static/images/smm-landing/plans.webp'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const BgImgBackground = styled(LocalImage)`
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  left: ${({ left }) => left || '50%'};
  top: ${({ top }) => top || 0};
  transform: translate(-50%);
  height: ${({ height }) => height || 'auto'};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  border-radius: ${({ borderRadius }) => borderRadius || 'unset'};
`

const StyledGetStartedButtonWrapper = styled(Flex)`
  box-shadow: 0px 18px 24px -8px rgb(36 130 253 / 32%);
`

const StyledButtonGradient = styled(Button.Gradient)`
  height: 100%;
`

const StyledDemoLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`

const StyledFeaturesWrapper = styled(Flex)`
  flex-direction: column;
  border-radius: ${radius.xxxl};
  background: radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063e3 36.77%, #0250c9 100%);
  padding: ${space.l};
`

const StyledFormWrapper = styled(Flex)`
  background: ${COLOR_CONSTANTS.BABY_BLUE_EYES};
  border-radius: ${radius.xxl};
  padding: ${space.m};
`

const FEATURE_ITEMS = [
  {
    title: 'Connect your client profiles in minutes',
    description: 'Get your client’s profiles connected or give your client the ability to do that.',
    icon: imageFeatureConnect,
  },
  {
    title: 'Load up their content calendar',
    description: 'Schedule a ton of content for them quickly for an immediate impact.',
    icon: imageFeatureCalendar,
  },
  {
    title: 'Assign team permissions',
    description: 'Quickly assign appropriate team members access and permissions.',
    icon: imageFeatureTeam,
  },
  {
    title: 'Enable client access to their account',
    description: 'Should your client be interested, give them access as well! They will only see their own data!',
    icon: imageFeatureAccess,
  },
]

const PROMO_LINK = 'https://vistasocial.com/pricing'
const DEMO_LINK = 'https://vistasocial.com/demo/?utm_source=smm+LP&utm_medium=event+promo&utm_campaign=smm+event'

const SMMLanding = () => {
  const addExternalScript = (url, callback) => {
    const script = document.createElement('script')
    script.src = url
    script.async = true

    if (callback) {
      script.onload = callback
    }

    document.body.appendChild(script)
  }

  useEffect(() => {
    addExternalScript('//js.hsforms.net/forms/embed/v2.js', () => {
      addExternalScript('/reelies-landing/hbspt_script.js')
    })
  }, [])

  return (
    <App fullHeader>
      <SEO
        title="Goodbye Average SMM Tools. 👋Vista Social: Feature Heavy, Future Ready."
        description="From bulk scheduling, boosting and highly customized reporting to endless integrations and employee advocacy; we take social media management to a whole new level. Seriously."
        path="/reelies/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb={{ mobile: 'l', desktop: 'xl' }} flexDirection="column" alignItems="center">
            <BgImgBackground
              src={bgImageSocialMedias}
              width="auto"
              objectFit={{ mobile: 'contain', tablet: 'cover' }}
              height="100%"
              display={{ mobile: 'none', tablet: 'block' }}
            />
            <Flex pt={{ mobile: 'l', tablet: 'xxl' }} alignItems="center" flexDirection="column" position="relative">
              <H1 fontSize="4xl" fontWeight="bold" textAlign="center" color={COLOR_CONSTANTS.DENIM}>
                Goodbye Average SMM Tools.
                <br />
                👋
                <H1
                  as="span"
                  fontSize="4xl"
                  fontWeight="bold"
                  color="primary"
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Vista Social
                </H1>
                : Feature Heavy, Future Ready.
              </H1>
            </Flex>
            <Flex mt="m" pt="s" alignItems="center" flexDirection="column" maxWidth="720px" zIndex="1">
              <Text color="secondaryText" fontSize="l">
                From bulk scheduling, boosting and highly customized reporting to endless integrations and employee
                advocacy; we take social media management to a whole new level. Seriously.
              </Text>
            </Flex>
            <Flex mt="xl" alignItems="center" flexDirection="column">
              <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                <StyledButtonGradient as="a" href={PROMO_LINK} target="_blank">
                  <Text p="m" fontSize="s">
                    Try Vista Social FREE
                  </Text>
                </StyledButtonGradient>
              </StyledGetStartedButtonWrapper>
              <Text color="secondaryText" mt="m" pt="s" pb="m">
                Social media’s best-kept secret for brands and agencies that mean business.
              </Text>
            </Flex>
          </Flex>
        </Container>

        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pb={{ mobile: 'l', tablet: 'xl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 's', desktop: 's' }}>
            <G2FeaturesComponent />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" mx="auto" maxWidth="968px">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Curious? Take{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Vista Social{' '}
              </H1>
              for a spin and see the magic before you commit —{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                no pressure, just proof
              </H1>
              .
            </H3>
          </Flex>

          <Flex mt="l" pt="s" alignItems="center" flexDirection="column">
            <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
              <StyledButtonGradient as="a" href={DEMO_LINK} target="_blank">
                <Text p="m" fontSize="s">
                  Book a FREE Strategy Session
                </Text>
              </StyledButtonGradient>
            </StyledGetStartedButtonWrapper>
          </Flex>

          <Flex mt="m" pt="s" justifyContent="center" flexDirection="row" alignItems="center" mx="auto">
            <Text fontSize="l" color="secondaryText" textAlign="center">
              Learn how Vista Social can help your business save time, stay ahead and collaborate faster! ⚡
            </Text>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(2, 1fr)' }} gridGap="l" alignItems="center">
            <Flex flexDirection="column" alignItems={{ mobile: 'center', desktop: 'flex-start' }}>
              <LocalImage src={logoBig} maxWidth="147px" alt="Vista Social" />

              <Flex mt="l" flexDirection="column">
                <H3
                  fontSize="3xl"
                  fontWeight="bold"
                  color={COLOR_CONSTANTS.DENIM}
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Stop Scrolling, Start Winning.
                </H3>
                <H3 fontSize="3xl" fontWeight="bold" color="primary" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Seriously, Don’t Think Twice.
                </H3>
              </Flex>

              <Flex flexDirection="column" mt="m">
                <Text fontSize="l" color="secondaryText" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Get you an SMM tool so slick, you'll wonder how you ever lived without us.
                </Text>
                <br />
                <Text fontSize="l" color="secondaryText" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  We are built to deliver unparalleled value to marketing agencies, freelancers and social media
                  managers. Plus, Vista's awesome team offers top-notch training, strategy advice, and fantastic live
                  support, all along the way!
                </Text>
              </Flex>

              <Flex mt="l" borderRadius={radius.m} bg={transparentize(0.9, colors.primary)} p="xs">
                <Text
                  color="primary"
                  px="xxs"
                  fontSize="l"
                  fontWeight="bold"
                  textAlign={{ mobile: 'center', tablet: 'left' }}
                >
                  Get a hands-on preview of Vista Social: deliver smarter and faster business impact today.
                </Text>
              </Flex>

              <Flex mt="l" pt="s" alignItems="flex-start" flexDirection="column">
                <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                  <StyledButtonGradient as="a" href={PROMO_LINK} target="_blank">
                    <Text p="m" fontSize="s">
                      Try Vista Social FREE
                    </Text>
                  </StyledButtonGradient>
                </StyledGetStartedButtonWrapper>
              </Flex>

              <Flex mt="m">
                <Text as="span" color="secondaryText" fontSize="m">
                  Not sure yet?
                </Text>
                &nbsp;
                <StyledDemoLink as="a" href={DEMO_LINK} target="_blank" fontSize="m" color="primary" fontWeight="bold">
                  Book a Demo
                </StyledDemoLink>
              </Flex>
            </Flex>

            <StyledFeaturesWrapper>
              <Grid gridTemplateColumns="1fr" gridGap="l" p="xs">
                {FEATURE_ITEMS.map(({ title, description, icon }) => {
                  return (
                    <Flex key={title} flexDirection="column">
                      <Flex alignItems="center">
                        <LocalImage src={icon} alt={title} width="44px" height="44px" />
                        <Text ml="s" color="white" fontWeight="bold" fontSize="l" textAlign="left">
                          {title}
                        </Text>
                      </Flex>
                      <Text mt="m" color="white" fontSize="m" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  )
                })}
              </Grid>
            </StyledFeaturesWrapper>
          </Grid>
        </Container>
      </Wrapper>

      <Wrapper position="relative" bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', desktop: 'l' }}
          pr={{ mobile: 'l', desktop: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
          zIndex="1"
        >
          <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(2, 1fr)' }} gridGap="l">
            <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '529px' }}>
              <Text
                color={COLOR_CONSTANTS.DENIM}
                fontSize="3xl"
                fontWeight="bold"
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                Request a personalized demo of{' '}
                <Text as="span" color="primary" fontSize="3xl" fontWeight="bold">
                  Vista Social
                </Text>
              </Text>

              <Flex mt="m" pt="xs" flexDirection="column">
                <Text fontSize="m" textAlign={{ mobile: 'center', desktop: 'left' }} color="secondaryText">
                  We're excited to show how our platform's powerful integrations, data, diverse tools and intuitive UX
                  can help scale your social media initiatives and gather insights to drive your business forward.
                </Text>
              </Flex>

              <Flex flexDirection="column" mt="xl">
                <LocalImage
                  src={imagePlans}
                  maxWidth={{ mobile: '100%', desktop: '529px' }}
                  alt="Request a personalized demo of Vista Social"
                />
              </Flex>
            </Flex>

            <Flex alignItems="center" mt={{ mobile: `-${space.l}`, tablet: `-${space.xl}`, desktop: 0 }}>
              <Box width="100%" minHeight="920px">
                <Calendly
                  link="https://calendly.com/d/ckr4-xgt-95z?hide_gdpr_banner=1"
                  height={{ mobile: '1200px', mobileLarge: '1170px', desktop: '1050px', desktopWide: '990px' }}
                />
              </Box>
            </Flex>
          </Grid>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default SMMLanding
